import React from 'react';
import {Helmet} from "react-helmet";
import { Link, StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Footer from "../components/Footer"
import p03becerritoAudio from "../audio/p03becerritoAudio.m4a"
import p03VO from "../audio/p03VO.m4a"


function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}
const images = importAll(require.context('../images/', false, /.jpg$|.png/));

class Page03 extends React.Component {
  constructor(props){
    super(props)
    this.state = { active: "" }
  }
  playAudio(x) {
    const audioEl = document.getElementsByClassName("audio-element")[x]
    audioEl.play()
  }
  render() {

    return (
			<div className="main-container">
      <Link to="/Page02">
        <div className="page-turner">
          <img style={{transform: "scaleX(-1)", opacity: 0.75}} className="hw" src={images["arrowPages.png"]} alt="" />
        </div>
      </Link>
      <Link to="/Page04">
        <div className="page-turner right-page-pos">
        <img style={{opacity: 0.75}} className="hw" src={images["arrowPages.png"]} alt="" />
        </div>
      </Link>
				<div className="centered-container">
					<div className="backContainer004">
            <StaticQuery
              query={graphql`
                query HeadingQuery {
                  imageOne: file(relativePath: { eq: "p03_back.jpg" }) {
                    childImageSharp {
                      fluid(maxWidth: 1000) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              `}
        render={data => (
          <Img fluid={data.imageOne.childImageSharp.fluid} />
        )}
      />
					</div>
					<div className="p03Cuentamelo" onClick={() => this.playAudio(1)}>
						<img className="hw" src={images["botonCuentamelo.png"]} alt="" />
					</div>
					<div className="_idContainer017">
						<img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p03_becerroCola.png"]} alt="" />
					</div>
					<div className="_idContainer018">
						<img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p03_becerro.png"]} alt="" />
					</div>
					<div className="_idContainer022">
						<div className="_idContainer019">
							<img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p03_becerroOrejaIzq.png"]} alt="" />
						</div>
						<div className="_idContainer020">
							<img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p03_becerroCabeza.png"]} alt="" />
						</div>
						<div className="_idContainer021">
							<img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p03_becerroOrejaDer.png"]} alt="" />
						</div>
					</div>
					<div className="_idContainer023 Basic-Text-Frame">
						<span>Becerrito era nuevo en El Gran Llano, pero ya había descubierto que ahí pasaban muchas cosas.</span>
					</div>
					<div className="_idContainer024" onClick={() => this.playAudio(0)}>
						<img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["4250.png"]} alt="" />
					</div>
					<audio className="audio-element">
            <source src={p03becerritoAudio}></source>
          </audio>
					<audio className="audio-element">
            <source src={p03VO}></source>
          </audio>
				</div>
      <Footer />
			</div>
    )
  }
}
export default Page03;
